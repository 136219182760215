import { request, noTimeOutReq } from "@/utils/request.js";

// 分页展示所有【物品标签表信息】
export function getMaterialTagInfoApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/experiment/itemtag/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 新增【物品标签数据】
export function addMaterialTagApi(data) {
  return request({
    url: `/sys/experiment/itemtag/add`,
    method: "post",
    data,
  });
}

// 删除【物品标签数据】
export function deleteMaterialTagApi(itemTagId) {
  return request({
    url: `/sys/experiment/itemtag/delete`,
    method: "post",
    params: {
      itemTagId,
    },
  });
}

// 获取【物品标签数据】详情
export function getMaterialTagDetailApi(itemTagId) {
  return request({
    url: `/sys/experiment/itemtag/get`,
    method: "post",
    params: {
      itemTagId,
    },
  });
}

// 修改【物品标签数据】
export function editMaterialTagApi(data) {
  return request({
    url: `/sys/experiment/itemtag/update`,
    method: "post",
    data,
  });
}
