<template>
  <div>
    <!-- 顶部按钮 -->
    <el-row style="margin-bottom: 20px">
      <el-button
        type="primary"
        v-throttle
        icon="el-icon-upload2"
        @click="
          () => {
            impartDialogVisible = true;
          }
        "
        >导入</el-button
      >
      <el-button
        icon="el-icon-circle-plus-outline"
        type="primary"
        v-throttle
        @click="handleAddStandardBtnClick"
        >添加物品标准</el-button
      >
    </el-row>
    <!-- 查询区域 -->
    <el-row>
      <el-form inline :model="deviceLibCondition">
        <!-- 国标编号 -->
        <el-form-item class="formItemBoxStyle" label="国标编号">
          <el-input
            v-model="deviceLibCondition.internationCode"
            placeholder="请输入"
            style="width: 200px"
            @blur="getMaterialData"
          ></el-input>
        </el-form-item>
        <!-- 名称 -->
        <el-form-item class="formItemBoxStyle" label="名称">
          <el-input
            v-model="deviceLibCondition.materialName"
            placeholder="请输入"
            style="width: 200px"
            @blur="getMaterialData"
          ></el-input>
        </el-form-item>
        <!-- 危化品 -->
        <el-form-item class="formItemBoxStyle" label="是否危化品">
          <el-select
            @change="getMaterialData"
            v-model="deviceLibCondition.materialHasDanger"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <!-- 设备状态 -->
        <el-form-item class="formItemBoxStyle" label="设备状态">
          <el-select
            @change="getMaterialData"
            v-model="deviceLibCondition.enabled"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" :value="1"></el-option>
            <el-option label="停用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item style="margin-left: 20px">
          <el-button v-throttle @click="handleResetMaterialBtn">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-throttle @click="handleQueryMaterialBtn"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 表格区域 -->
    <el-row>
      <el-table
        :data="tableDataList"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="internationCode"
          label="国标编号"
          center
        ></el-table-column>
        <el-table-column prop="materialName" label="名称" center>
          <template slot-scope="scoped">
            <div
              @click="handleDeviceNameClick(scoped.row)"
              style="color: #00f; text-decoration: underline; cursor: pointer"
            >
              {{ scoped.row.materialName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="materialSpecifications"
          label="规格型号功能"
          center
        ></el-table-column>
        <el-table-column
          prop="materialUnit"
          label="单位"
          center
        ></el-table-column>
        <el-table-column
          prop="materialUnitPrice"
          label="参考单价"
          center
        ></el-table-column>
        <el-table-column label="标准数量" center>
          <template slot-scope="scoped">
            {{ scoped.row.materialMinStandardNum }} ~
            {{ scoped.row.materialMaxStandardNum }}
          </template>
        </el-table-column>
        <el-table-column
          prop="materialExecuteCode"
          label="执行标准"
          center
        ></el-table-column>
        <el-table-column prop="materialHasDanger" label="是否危化品" center>
          <template slot-scope="scoped">
            {{ scoped.row.materialHasDanger ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="materialHasConsumables"
          label="是否消耗品"
          center
        >
          <template slot-scope="scoped">
            {{ scoped.row.materialHasConsumables ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          label="备注"
          center
        ></el-table-column>
        <el-table-column prop="enabled" label="状态" center>
          <template slot-scope="scoped">
            {{ scoped.row.enabled === 1 ? "启用" : "停用" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" center>
          <template slot-scope="scoped">
            <!-- 停用状态 -->
            <div v-if="scoped.row.enabled === 0" style="display: flex">
              <div
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  color: green;
                  text-decoration: underline;
                "
                @click="handleEditStandardBtnClick(scoped.row)"
              >
                编辑
              </div>
              <div
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  color: rgb(64, 158, 255);
                  text-decoration: underline;
                "
                @click="
                  handleOpenChangeMaterialDialog('启用', scoped.row.materialId)
                "
              >
                启用
              </div>
              <div
                @click="
                  handleOpenChangeMaterialDialog('删除', scoped.row.materialId)
                "
                style="cursor: pointer; color: red; text-decoration: underline"
              >
                删除
              </div>
            </div>
            <!-- 启动状态 -->
            <div v-else>
              <div
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  color: red;
                  text-decoration: underline;
                "
                @click="
                  handleOpenChangeMaterialDialog('停用', scoped.row.materialId)
                "
              >
                停用
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeTableDataMsgPage"
        @current-change="handleCurrentChangeTableDataMsgPage"
        :current-page="tableDataMsgPage.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableDataMsgPage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataMsgPage.pageTotal"
      ></el-pagination>
    </el-row>
    <!-- 导入弹框 -->
    <el-dialog
      :visible.sync="impartDialogVisible"
      :before-close="
        () => {
          impartDialogVisible = false;
        }
      "
      width="600px"
      title="导入"
    >
      <div class="impart-dialog-container">
        <div class="impart-dialog-box">
          <div style="font-size: 18px; font-weight: 700; color: #000">
            下载模板
          </div>
          <div
            style="
              margin-top: 20px;
              text-align: center;
              font-size: 16px;
              color: blue;
              text-decoration: underline;
              cursor: pointer;
            "
            @click="handleImpartTemplateBtnClick"
          >
            点击下载模板
          </div>
          <div style="margin-top: 20px; text-align: center">
            请下载Excel模板，按文件要求填入内容，再进行上传（勿调整表头）
          </div>
        </div>
        <div class="impart-dialog-box">
          <div style="font-size: 18px; font-weight: 700; color: #000">
            导入结果
          </div>
          <div style="text-align: right">
            <el-upload
              class="upload-demo"
              multiple
              accept=".xls, .xlsx"
              :show-file-list="false"
              action="https://jsonplaceholder.typicode.com/posts/"
              :http-request="handleTableFileUpload"
            >
              <el-button
                ><i class="el-icon-upload2" style="margin-right: 5px"></i
                >选择导入文件</el-button
              >
            </el-upload>
          </div>
          <div style="margin-top: 15px; text-align: right">
            仅支持XLS/XLSX文件格式
          </div>
        </div>
        <div class="impart-dialog-btn">
          <el-button type="primary">立即导入</el-button>
          <el-button
            @click="
              () => {
                impartDialogVisible = false;
              }
            "
            >取消</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 添加物品标准弹框 -->
    <el-dialog
      :visible.sync="addStandardDialogVisible"
      :before-close="
        () => {
          addStandardDialogVisible = false;
        }
      "
      :title="addOrEdit ? '添加物品标准' : '编辑物品标准'"
      width="700px"
      top="2%"
    >
      <div class="form-container">
        <el-form
          :model="addStandardForm"
          ref="addStandardForm"
          label-width="120px"
          :rules="rules"
        >
          <el-form-item
            class="formItem redStar"
            label="国标编号："
            prop="internationalCode"
          >
            <el-input
              v-model.trim="addStandardForm.internationalCode"
              placeholder="请输入"
              maxlength="12"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="formItem redStar"
            label="名称："
            prop="deviceName"
          >
            <el-input
              v-model.trim="addStandardForm.deviceName"
              placeholder="请输入"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="型号功能："
            prop="specification"
          >
            <el-input
              v-model.trim="addStandardForm.specification"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="单位：" prop="unit">
            <el-input
              v-model.trim="addStandardForm.unit"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="参考单价："
            prop="referencePrice"
          >
            <el-input
              v-model.trim="addStandardForm.referencePrice"
              placeholder="请输入"
              @blur="handleReferencePriceInputBlur"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="执行标准："
            prop="materialExecuteCode"
          >
            <el-input
              v-model.trim="addStandardForm.materialExecuteCode"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="是否危化品："
            prop="isDangerous"
          >
            <el-select
              style="width: 461px"
              v-model="addStandardForm.isDangerous"
              placeholder="请选择"
            >
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="是否消耗品："
            prop="isConsumables"
          >
            <el-select
              style="width: 461px"
              v-model="addStandardForm.isConsumables"
              placeholder="请选择"
            >
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="formItem input-number"
            label="标准数量："
            prop="number"
          >
            <!-- <el-input
              v-model.trim="addStandardForm.number"
              placeholder="请输入"
            ></el-input> -->
            <el-input-number
              v-model="minNum"
              controls-position="right"
              :min="1"
              :max="maxNum - 1"
            ></el-input-number>
            <span> ~ </span>
            <el-input-number
              v-model="maxNum"
              controls-position="right"
              :min="minNum + 1"
              :max="1000"
            ></el-input-number>
          </el-form-item>
          <el-form-item class="formItem" label="物品标签：" prop="tag">
            <div style="position: relative">
              <el-input
                class="materialTagInput"
                placeholder="请添加"
                v-model.trim="addStandardForm.tag"
                maxlength="10"
                disabled
                type="textarea"
                :rows="2"
                resize="none"
              ></el-input>
              <div
                style="
                  position: absolute;
                  right: 20px;
                  top: 0;
                  color: #00f;
                  text-decoration: underline;
                  cursor: pointer;
                "
                @click="handleAddTagBtnClick"
              >
                + 添加
              </div>
            </div>
          </el-form-item>
          <el-form-item class="formItem" label="物品图片：">
            <div>
              <el-upload
                ref="pictureUpload"
                action="#"
                list-type="picture-card"
                :auto-upload="false"
                accept=".jpg, .png"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <div v-if="!addOrEdit">
                <div v-if="this.editDialogImageList.length > 0">
                  -- 已上传的图片 --
                </div>
                <el-image
                  v-for="(item, i) in this.editDialogImageList"
                  :key="i"
                  style="width: 150px; height: 150px; margin-right: 10px"
                  :src="item"
                  fit="cover"
                ></el-image>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="formItem" label="备注：" prop="remark">
            <el-input
              v-model.trim="addStandardForm.remark"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: right">
        <el-button
          @click="
            () => {
              addStandardDialogVisible = false;
            }
          "
          >取消</el-button
        >
        <el-button type="primary" @click="handleConfirmStandardBtnClick"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <!-- 图片预览弹框 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <!-- 添加标签弹框 -->
    <el-dialog
      :visible.sync="addTagDialogVisible"
      :before-close="
        () => {
          addTagDialogVisible = false;
        }
      "
      title="添加物品标准"
      width="680px"
    >
      <div class="tag-title">已选择</div>
      <div class="tag-content">
        <div
          v-for="item in addMaterialTagList"
          :key="item.itemTagId"
          class="tag-content-item"
        >
          {{ item.itemTagName }}
          <div class="tag-item-x" @click="handleRemoveSelectTagBtn(item)">
            X
          </div>
        </div>
      </div>
      <div class="tag-title">物品标签库</div>
      <div style="margin-top: 10px">
        <el-form inline :model="tagLibCondition">
          <el-form-item>
            <span style="margin-right: 10px">标签编号</span>
            <el-input
              placeholder="请输入"
              v-model="tagLibCondition.itemTagCode"
              style="width: 150px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span style="margin-right: 10px">标签名称</span>
            <el-input
              placeholder="请输入"
              v-model="tagLibCondition.itemTagName"
              style="width: 150px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button v-throttle @click="handleResetTagTableData"
              >重置</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQueryTabTableData"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          border
          :data="tagTableDataList"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            label="国标编号"
            prop="itemTagCode"
          ></el-table-column>
          <el-table-column label="名称" prop="itemTagName"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scoped">
              <div
                v-if="!handleMaterialTagStatusCheck(scoped.row)"
                style="color: #00f; text-decoration: underline; cursor: pointer"
                @click="handleAddMaterialTagBtnClick(scoped.row)"
              >
                添加
              </div>
              <div v-else style="color: #ccc">已添加</div>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin: 10px 0">
          <el-pagination
            @size-change="handleSizeChangeTagTableDataMsgPage"
            @current-change="handleCurrentChangeTagTableDataMsgPage"
            :current-page="tagTableDataMsgPage.pageIndex"
            :page-sizes="[5, 10, 20, 50, 100, 150]"
            :page-size="tagTableDataMsgPage.pageSize"
            layout="->,total,  prev, pager, next,sizes, jumper"
            :total="tagTableDataMsgPage.pageTotal"
          ></el-pagination>
        </div>
      </div>
      <div style="text-align: right">
        <el-button @click="handleCancelTagDataBtn">取消</el-button>
        <el-button type="primary" @click="handleSaveTagData">保存</el-button>
      </div>
    </el-dialog>
    <!-- 设备耗材查看弹框 -->
    <el-dialog
      title="物品标准"
      width="600px"
      :visible.sync="checkDialogVisible"
      top="1%"
    >
      <div class="form-container">
        <el-form
          :model="deviceInfo"
          ref="deviceInfo"
          label-width="100px"
          :rules="rules"
        >
          <el-form-item
            class="formItem redStar"
            label="国标编号："
            prop="internationalCode"
          >
            <div>{{ deviceInfo.internationalCode }}</div>
          </el-form-item>
          <el-form-item
            class="formItem redStar"
            label="名称："
            prop="deviceName"
          >
            <div>{{ deviceInfo.deviceName }}</div>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="型号功能："
            prop="specification"
          >
            <div>{{ deviceInfo.deviceName }}</div>
          </el-form-item>
          <el-form-item class="formItem" label="单位：" prop="unit">
            <div>{{ deviceInfo.unit }}</div>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="参考单价："
            prop="referencePrice"
          >
            <div>{{ deviceInfo.referencePrice }}</div>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="是否危化品："
            prop="isDangerous"
          >
            <div>{{ deviceInfo.isDangerous }}</div>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="是否消耗品："
            prop="isConsumables"
          >
            <div>{{ deviceInfo.isConsumables }}</div>
          </el-form-item>
          <el-form-item class="formItem" label="标准数量：" prop="number">
            <div>{{ minNum }} ~ {{ maxNum }}</div>
          </el-form-item>
          <el-form-item class="formItem" label="物品标签：" prop="tag">
            <div>{{ deviceInfo.tag }}</div>
          </el-form-item>
          <el-form-item class="formItem" label="物品图片：">
            <el-image
              v-for="(item, i) in this.editDialogImageList"
              :key="i"
              style="width: 150px; height: 150px; margin-right: 10px"
              :src="item"
              fit="cover"
            ></el-image>
          </el-form-item>
          <el-form-item class="formItem" label="备注：" prop="remark">
            <div>{{ deviceInfo.remark }}</div>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: right">
        <el-button
          @click="
            () => {
              checkDialogVisible = false;
            }
          "
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMaterialListApi,
  addMaterialApi,
  deleteMaterialApi,
  getMaterialDetailApi,
  editMaterialApi,
  uploadMaterialPicApi,
} from "@/api/standard/standardDeviceLib.js";
import { getMaterialTagInfoApi } from "@/api/standard/tagManagement.js";
import { verifyInput } from "@/utils/util";
export default {
  name: "standardDeviceLib",
  data() {
    const checkInternationalCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z-_]/g;
      if (reg.test(this.addStandardForm.internationalCode)) {
        callback(new Error("编号只能是数字或字母"));
      } else {
        callback();
      }
    };
    return {
      deviceLibCondition: {
        internationCode: "",
        materialName: "",
        materialHasDanger: "",
        enabled: "",
      },
      tableDataList: [],
      tableDataMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      impartDialogVisible: false,
      addStandardDialogVisible: false,
      addTagDialogVisible: false,
      checkDialogVisible: false,
      banDelStartDialogVisible: false,
      addStandardForm: {
        internationalCode: "",
        deviceName: "",
        specification: "",
        unit: "",
        referencePrice: "",
        number: "",
        materialExecuteCode: "",
        isDangerous: "",
        isConsumables: "",
        tag: "",
        remark: "",
      },
      addItemTagIds: [], // 添加物品标签id数组
      imageUrlList: [],
      editDialogImageList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      addOrEdit: 1, // 1表示添加，0表示编辑
      changeBtnDialogTxt: "",
      rules: {
        internationalCode: [
          { required: true, message: "请输入国标编号", trigger: "blur" },
          { validator: checkInternationalCode, trigger: "blur" },
        ],
        deviceName: [
          { required: true, message: "请输入名称", trigger: "blur" },
        ],
        isDangerous: [
          { required: true, message: "请选择是否危化品", trigger: "change" },
        ],
        isConsumables: [
          { required: true, message: "请选择是否消耗品", trigger: "change" },
        ],
        tag: [
        { required: true, message: "请选择物品标签", trigger: "change" },
        ]
      },
      tagLibCondition: {
        itemTagCode: "",
        itemTagName: "",
      },
      tagTableDataList: [],
      tempTagTableDataList: [],
      tagTableDataMsgPage: {
        pageIndex: 1,
        pageSize: 5,
        pageTotal: 0,
      },
      isSavingTag: false,
      deviceInfo: {
        internationalCode: "",
        deviceName: "",
        specification: "",
        unit: "",
        referencePrice: "",
        number: "",
        isDangerous: "",
        isConsumables: "",
        tag: "",
        remark: "",
      },
      addMaterialTagList: [], // 已添加的标签
      addMaterialTagIdList: [], // 已经上传的标签ID
      deleteMaterialTagList: [],
      minNum: 1, // 标准数量最小值
      maxNum: 0, // 标准数量最大值
    };
  },
  watch: {
    addTagDialogVisible: function (val) {
      if (!val && !this.isSavingTag) {
        this.handleCancelTagDataBtn();
      } else {
        this.isSavingTag = false;
      }
    },
  },
  created() {
    this.getMaterialData();
    this.fetchMaterialTagData();
  },
  methods: {
    // 获取耗材数据
    getMaterialData() {
      getMaterialListApi(
        this.tableDataMsgPage.pageIndex,
        this.tableDataMsgPage.pageSize,
        this.deviceLibCondition
      ).then((res) => {
        if (res.success) {
          this.tableDataList = res.data;
          this.tableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取物品标签页信息
    fetchMaterialTagData() {
      getMaterialTagInfoApi(
        this.tagTableDataMsgPage.pageIndex,
        this.tagTableDataMsgPage.pageSize,
        this.tagLibCondition
      ).then((res) => {
        if (res.success) {
          console.log(res, "res");
          this.tagTableDataList = res.data;
          this.tagTableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 改变当前页
    handleCurrentChangeTableDataMsgPage(val) {
      this.tableDataMsgPage.pageIndex = val;
      this.getMaterialData();
    },
    // 改变页容量
    handleSizeChangeTableDataMsgPage(val) {
      this.tableDataMsgPage.pageIndex = 1;
      this.tableDataMsgPage.pageSize = val;
      this.getMaterialData();
    },
    // 重置按钮
    handleResetMaterialBtn() {
      this.deviceLibCondition = {
        internationalCode: "",
        materialName: "",
        materialHasDanger: "",
        enabled: "",
      };
      this.tableDataMsgPage.pageIndex = 1;
      this.tableDataMsgPage.pageSize = 10;
      this.getMaterialData();
    },
    // 查询按钮
    handleQueryMaterialBtn() {
      this.tableDataMsgPage.pageIndex = 1;
      this.tableDataMsgPage.pageSize = 10;
      this.getMaterialData();
    },
    // 重置标签表格按钮
    handleResetTagTableData() {
      this.tagLibCondition = {
        itemTagCode: "",
        itemTagName: "",
      };
      this.tagTableDataMsgPage.pageIndex = 1;
      this.tableDataMsgPage.pageSize = 5;
      this.fetchMaterialTagData();
    },
    // 查询标签表格按钮
    handleQueryTabTableData() {
      this.tagTableDataMsgPage.pageIndex = 1;
      this.tableDataMsgPage.pageSize = 5;
      this.fetchMaterialTagData();
    },
    // 物品标签库分页-改变当前页
    handleCurrentChangeTagTableDataMsgPage(val) {
      this.tagTableDataMsgPage.pageIndex = val;
      this.fetchMaterialTagData();
    },
    // 物品标签库分页-改变页容量
    handleSizeChangeTagTableDataMsgPage() {
      this.tagTableDataMsgPage.pageIndex = 1;
      this.tagTableDataMsgPage.pageSize = val;
      this.fetchMaterialTagData();
    },
    // 停用、启动、删除按钮
    handleOpenChangeMaterialDialog(val, materialId) {
      this.changeBtnDialogTxt = val;
      this.$confirm(`确定${val}该物品标准吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (val === "删除") {
            // 删除操作
            deleteMaterialApi(materialId).then((res) => {
              if (res.success) {
                this.getMaterialData();
                this.$message({
                  type: "success",
                  message: `${val}成功!`,
                });
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 启动或停用操作
            let params = {
              materialId,
              enabled: val === "启用" ? 1 : 0,
            };
            editMaterialApi(params).then((res) => {
              if (res.success) {
                this.getMaterialData();
                this.$message({
                  type: "success",
                  message: `${val}成功!`,
                });
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${val}`,
          });
        });
    },
    // 下载模板
    handleImpartTemplateBtnClick() {},
    // 表格文件上传
    handleTableFileUpload() {},
    // 移除预览图片
    handleRemove(file) {
      let uploadFiles = this.$refs.pictureUpload.uploadFiles;
      for (let i = 0; i < uploadFiles.length; i++) {
        if (file.url === uploadFiles[i]["url"]) {
          uploadFiles.splice(i, 1);
        }
      }
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 添加按钮
    handleAddStandardBtnClick() {
      this.addOrEdit = 1;
      this.addStandardForm = {
        internationalCode: "",
        deviceName: "",
        specification: "",
        unit: "",
        referencePrice: "",
        number: "",
        isDangerous: "",
        isConsumables: "",
        tag: "",
        materialExecuteCode: "",
        remark: "",
      };
      this.minNum = 1;
      this.maxNum = 0;
      this.editDialogImageList = [];
      this.imageUrlList = [];
      this.addMaterialTagList = [];
      this.$refs.pictureUpload && (this.$refs.pictureUpload.uploadFiles = []);
      this.addStandardDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.addStandardForm.clearValidate();
      });
    },
    // 编辑按钮
    handleEditStandardBtnClick(row) {
      console.log(row);
      this.addOrEdit = 0;
      this.editDialogImageList = [];
      this.imageUrlList = [];
      this.deleteMaterialTagList = [];
      this.addMaterialTagList = [];
      this.addMaterialTagIdList = []; // 已添加的标签ID数组
      this.$refs.pictureUpload && (this.$refs.pictureUpload.uploadFiles = []);
      // 调用接口，回填数据
      getMaterialDetailApi(row.materialId).then((res) => {
        if (res.success) {
          // console.log(res, "res");
          this.addStandardForm = {
            internationalCode: res.data.internationCode,
            deviceName: res.data.materialName,
            specification: res.data.materialSpecifications,
            unit: res.data.materialUnit,
            referencePrice: res.data.materialUnitPrice,
            // number: res.data.materialStandardNumber,
            isDangerous: Number(res.data.materialHasDanger),
            isConsumables: Number(res.data.materialHasConsumables),
            tag: "",
            remark: res.data.description,
            enabled: res.data.enabled,
            imgUrl: res.data.imgUrl,
            materialId: res.data.materialId,
            materialExecuteCode: res.data.materialExecuteCode,
          };
          this.minNum = res.data.materialMinStandardNum;
          this.maxNum = res.data.materialMaxStandardNum;
          res.data.imgUrl.forEach((item) => {
            this.editDialogImageList.push(this.$imageAddress(item));
          });
          // 填充标签一栏
          this.addMaterialTagList = res.data.materialItemTagDTOS;
          this.addMaterialTagList?.forEach((item) => {
            this.addMaterialTagIdList.push(item.itemTagId);
          });
          this.handleFillTagFormItem();
          this.addStandardDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 辅助方法，去除一个数组内已经用的项
    testArrFunc(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          if (arr1[i] == arr2[j]) {
            arr1.splice(i, 1);
            i--;
            break;
          }
        }
      }
      return arr1;
    },
    // 添加或编辑确认按钮
    handleConfirmStandardBtnClick() {
      this.$refs.addStandardForm.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit) {
            // 这里执行添加标准操作
            // 如果有传图片，先调用图片上传接口获取 imgUrl
            if (this.$refs.pictureUpload.uploadFiles.length > 0) {
              await this.getUploadImgUrl();
              if (!this.imageUrlList.length) {
                return;
              }
            }
            let addParams = {
              addItemTagIds: this.addItemTagIds,
              description: this.addStandardForm.remark,
              imgUrl: this.imageUrlList,
              internationCode: this.addStandardForm.internationalCode,
              materialUnit: this.addStandardForm.unit,
              materialHasConsumables: Boolean(
                this.addStandardForm.isConsumables
              ),
              materialHasDanger: Boolean(this.addStandardForm.isDangerous),
              materialName: this.addStandardForm.deviceName,
              materialSpecifications: this.addStandardForm.specification,
              // materialStandardNumber: this.addStandardForm.number,
              materialUnitPrice: this.addStandardForm.referencePrice,
              enabled: 0,
              materialMinStandardNum: this.minNum,
              materialMaxStandardNum: this.maxNum,
              materialExecuteCode: this.addStandardForm.materialExecuteCode,
            };
            addMaterialApi(addParams).then((res) => {
              if (res.success) {
                this.$message.success("添加成功!");
                this.getMaterialData();
                this.addStandardDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 这里执行编辑标准操作
            // 先判断是否有上传图片
            let uploadImgLength = 0;
            if (this.$refs.pictureUpload.uploadFiles.length > 0) {
              await this.getUploadImgUrl();
              if (!this.imageUrlList.length) {
                return;
              }
              uploadImgLength = 1;
            }
            let editParams = {
              addItemTagIds: this.testArrFunc(
                this.addItemTagIds,
                this.addMaterialTagIdList
              ),
              description: this.addStandardForm.remark,
              imgUrl:
                uploadImgLength === 1
                  ? this.imageUrlList
                  : this.addStandardForm.imgUrl,
              internationCode: this.addStandardForm.internationalCode,
              materialUnit: this.addStandardForm.unit,
              materialHasConsumables: Boolean(
                this.addStandardForm.isConsumables
              ),
              materialHasDanger: Boolean(this.addStandardForm.isDangerous),
              materialName: this.addStandardForm.deviceName,
              materialSpecifications: this.addStandardForm.specification,
              // materialStandardNumber: this.addStandardForm.number,
              materialUnitPrice: this.addStandardForm.referencePrice,
              enabled: 0,
              materialId: this.addStandardForm.materialId,
              delItemTagRelationIds: this.deleteMaterialTagList,
              materialMinStandardNum: this.minNum,
              materialMaxStandardNum: this.maxNum,
              materialExecuteCode: this.addStandardForm.materialExecuteCode,
            };
            editMaterialApi(editParams).then((res) => {
              if (res.success) {
                this.$message.success("修改成功!");
                this.getMaterialData();
                this.addStandardDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 这里调用图片接口上传图片
    async getUploadImgUrl() {
      let fd = new FormData();
      this.$refs.pictureUpload.uploadFiles.forEach((item) => {
        fd.append("images", item.raw);
      });
      const res = await uploadMaterialPicApi(fd);
      if (res.success) {
        this.imageUrlList = res.data;
      } else {
        if (res.msg === "上传文件格式不正确，请检查文件格式") {
          res.msg = "上传文件格式不正确，仅支持 .jpg，.png 格式图片";
        }
        this.$message.error(res.msg);
        return;
      }
    },
    // 移除已选择的标签
    handleRemoveSelectTagBtn(val) {
      for (let index = 0; index < this.addMaterialTagList.length; index++) {
        if (val.itemTagCode === this.addMaterialTagList[index].itemTagCode) {
          // 如果有可以删除的关系id，这带上
          this.addMaterialTagList[index].materialItemTagRelationId &&
            this.deleteMaterialTagList.push(
              this.addMaterialTagList[index].materialItemTagRelationId
            );
          this.addMaterialTagList.splice(index, 1);
        }
      }
    },
    // 添加标签按钮
    handleAddTagBtnClick() {
      this.tempTagTableDataList = [];
      for (let i = 0; i < this.tagTableDataList.length; i++) {
        let obj = {};
        for (const key in this.tagTableDataList[i]) {
          obj[key] = this.tagTableDataList[i][key];
        }
        this.tempTagTableDataList.push(obj);
      }
      this.addTagDialogVisible = true;
    },
    // 保存标签按钮
    handleSaveTagData() {
      this.isSavingTag = true;
      // 填入表格数据
      this.handleFillTagFormItem();
      this.addTagDialogVisible = false;
    },
    // 调用该方法填入表单中的 标签 一栏
    handleFillTagFormItem() {
      this.addStandardForm.tag = "";
      this.deviceInfo.tag = "";
      this.addItemTagIds = [];
      this.addMaterialTagList?.forEach((item) => {
        this.addItemTagIds.push(item.itemTagId);
        if (!this.addStandardForm.tag) {
          this.addStandardForm.tag += item.itemTagName;
        } else {
          this.addStandardForm.tag += "，" + item.itemTagName;
        }
      });
      this.deviceInfo.tag = this.addStandardForm.tag;
    },
    // 调用该方法可以判断标签表格一栏是显示 添加 还是 已添加
    handleMaterialTagStatusCheck(row) {
      let result = false;
      if (this.addMaterialTagList) {
        this.addMaterialTagList.forEach((item) => {
          if (item.itemTagId === row.itemTagId) {
            result = true;
          }
        });
      }
      return result;
    },
    // 标签表格中添加按钮
    handleAddMaterialTagBtnClick(row) {
      if (!this.addMaterialTagList) {
        this.addMaterialTagList = [];
      }
      this.addMaterialTagList.push(row);
    },
    // 取消标签修改按钮
    handleCancelTagDataBtn() {
      this.tagTableDataList = this.tempTagTableDataList;
      this.addTagDialogVisible = false;
    },
    // 物品名称点击事件处理
    handleDeviceNameClick(row) {
      this.editDialogImageList = [];
      getMaterialDetailApi(row.materialId).then((res) => {
        if (res.success) {
          // 回填数据
          const { data } = res;
          this.deviceInfo = {
            internationalCode: data.internationCode,
            deviceName: data.materialName,
            specification: data.materialSpecifications,
            unit: data.materialUnit,
            referencePrice: data.materialUnitPrice,
            // number: data.materialStandardNumber,
            isDangerous: data.materialHasDanger ? "是" : "否",
            isConsumables: data.materialHasConsumables ? "是" : "否",
            tag: "",
            remark: data.description,
          };
          data.imgUrl.forEach((item) => {
            this.editDialogImageList.push(this.$imageAddress(item));
          });
          this.minNum = res.data.materialMinStandardNum;
          this.maxNum = res.data.materialMaxStandardNum;
          // 填充标签一栏
          this.addMaterialTagList = data.materialItemTagDTOS;
          this.handleFillTagFormItem();
          this.checkDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 校验单价输入框
    handleReferencePriceInputBlur() {
      this.addStandardForm.referencePrice = verifyInput(
        this.addStandardForm.referencePrice,
        2,
        1000
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.formItemName {
  margin-right: 10px;
  margin-left: 16px;
}
.impart-dialog-container {
  padding: 10px 20px;
}
.impart-dialog-box {
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 8px;
}
.impart-dialog-btn {
  width: 180px;
  height: 40px;
  margin: 0 auto;
  display: flex;
}
.form-container {
  width: 85%;
  margin: 0 auto;
}
::v-deep .redStar {
  .el-form-item__label::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
.redStar1::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.tag-title {
  padding-left: 10px;
  border-left: 1px solid #ccc;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  margin-bottom: 25px;
}
.tag-content {
  margin-bottom: 25px;
  width: 95%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 15px 10px 15px;
}
.tag-content-item {
  padding:0 10px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  position: relative;
  display: inline-block;
  margin-right: 40px;
  margin-top: 10px;
}
.tag-item-x {
  position: absolute;
  right: -22px;
  top: -1px;
  height: 35px;
  width: 20px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
.check-dialog-container {
  width: 500px;
  margin: 0 auto;
}
::v-deep .input-number {
  .el-input-number__decrease,
  .el-input-number__increase {
    width: 20px;
  }
}
::v-deep .materialTagInput {
  .el-textarea__inner {
    padding-right: 80px;
  }
}
</style>
